<template>
  <div class="d-flex flex-column align-items-center text-center body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column align-items-center" v-if="completedQuiz !== false">
      <div class="mt-5 col-9 well-done-header-text">WELL DONE!
      </div>
      <img class="mt-1  col-9" src="../assets/game/underline-icon.png">
      <div>You completed the quiz and earned:</div>
      <img class="mt-1 col-4" :src="require(`../assets/game/score-icon-${userCorrectAnswers}.png`)">
      <div class="mt-2 points-text">{{ userCorrectAnswers * 100 }} POINTS!
      </div>
      <div>You scored {{ userCorrectAnswers }}/10</div>
    </div>
    <div class="d-flex flex-column align-items-center" v-if="completedQuiz === false">
      <div class="mt-5 col-9 well-done-header-text">OOPS!
      </div>
      <img class="mt-1  col-9" src="../assets/game/underline-icon.png">
      <div>You did not answer in time</div>
      <img class="mt-3 col-4" :src="require(`../assets/game/score-icon-${userCorrectAnswers}.png`)">
      <div class="mt-2 points-text">{{ userCorrectAnswers * 100 }} POINTS!
      </div>
      <div>You only answered {{ completedQuestions }} questions</div>
    </div>
    <button @click="homeButtonClicked()" class="col-9 mt-3 home-button">HOME</button>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: "ResultsPage",
  computed: {
    ...mapState(['userCorrectAnswers', 'completedQuiz', 'completedQuestions', 'isLoading']),
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    homeButtonClicked() {
      this.$router.push('/landing-page');
    }
  },
  checkNoCorrectAnswers() {
    if (this.userCorrectAnswers === undefined) {
      this.userCorrectAnswers = 0
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.body-container {
  font-size: 16px;
  color: #ffffff;
}

.well-done-header-text {
  font-family: "Komikax", sans-serif;
  font-size: 22px;
}

.points-text {
  font-family: "Komikax", sans-serif;
  font-size: 32px;
}

.home-button {
  font-family: "Komikax", sans-serif;
  font-size: 22px;
  color: #ffffff;
  height: 55px;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
  background-color: #950033;
}
</style>

import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    userNumber: `${process.env.VUE_APP_USER_NUMBER}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`
}

export const stagingConfig = {
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    userNumber: `${process.env.VUE_APP_USER_NUMBER}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`
}


const environment = getEnvironment()

export const environmentConfig = getEnvObject();


function getEnvironment() {
    var base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}

<template>
  <div class="d-flex flex-column align-items-center body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column align-items-center text-center" v-if="displayLetsPlayButton">
      <div class="promo-text col-6">Play Sport Trivia & WIN your share of <span>7.5GB daily!</span>
      </div>
      <img class="mt-3 col-5" src="../assets/game/landing-page-main-icon.png">
      <button @click="letsPlayClicked()" class="mt-3 col-10 lets-play-button">LET'S PLAY
      </button>
    </div>
    <div class="d-flex flex-column align-items-center text-center played-game-container" v-if="!displayLetsPlayButton">
      <img class="mt-5 col-8" src="../assets/game/played-today-icon.png">
      <button @click="quizIncButtonClicked" class="mt-3 col-10 quiz-inc-button">GO BACK TO QUIZ INC
      </button>
    </div>
    <button @click="howItWorksClicked()" class="mt-1 col-6 how-it-works-button">HOW IT WORKS</button>
    <div @click="onTnCsClicked()" class="tncs">T's and C's apply</div>
  </div>
</template>

<script>
import UserDataService from "@/services/userDataService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "LandingPage",
  data() {
    return {
      displayLetsPlayButton: true,
    }
  },
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    onTnCsClicked() {
      this.$router.push("/terms-and-conditions")
    },
    letsPlayClicked() {
      this.$router.push('/question-page')
    },
    howItWorksClicked() {
      this.$router.push('/how-it-works')
    },
    quizIncButtonClicked() {
      const redirectUrl = `https://quizinc.co.za/optin/service/4069?bc_id=63&qi-subscription-id=${this.user.subscriptionId}`;
      window.open(redirectUrl, '_self');
    },
    async checkUserAnsweredTodayQuestions() {
      let getUserResultsResponse = await UserDataService.getUserResults(this.user.msisdn);
      let key = Object.keys(getUserResultsResponse.data.recordset[0])
      if (key[0] === 'TotalCorrectAnswersToday') {
        this.displayLetsPlayButton = false;
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkUserAnsweredTodayQuestions();
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.tncs {
  margin-top: 5px;
  text-decoration: underline;
}

.tncs:hover {
  cursor: pointer;
  color: blue;
}

@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.body-container {
  color: #FFFFFF;
  overflow-y: hidden;
}

.promo-text {
  margin-top: 50px;
  font-size: 18px;
}

span {
  font-weight: 700;
}

.lets-play-button {
  height: 55px;
  border: 0;
  background-color: #07bd07;
  font-family: "Komikax", sans-serif;
  border-radius: 12px;
  color: #FFFFFF;
}

.how-it-works-button {
  height: 40px;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  font-family: "Komikax", sans-serif;
  border-radius: 12px;
  color: #FFFFFF;
}

.played-game-container {
  margin-top: 30px;
}

.quiz-inc-button {
  height: 55px;
  border: 0;
  background-color: #d9d9d9;
  font-family: "Komikax", sans-serif;
  border-radius: 12px;
  color: #FFFFFF;
}

</style>

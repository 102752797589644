<template>
  <div class="d-flex flex-column justify-content-center align-items-center body-container">
    <div v-if="displayCoinsContainer" class="d-flex flex-row text-center col-3 mt-1 coins-container">
      <img src="../assets/game/coin-icon.png">
      <div class="multiply-text">x</div>
      <div class="d-flex flex-column points-text">
        <div>{{ pointScore }}</div>
        <div>POINTS</div>
      </div>
    </div>
    <img class="col-7 col-sm-8 col-md-8 col-lg-8 col-xl-10 trivia-title-img"
         src="../assets/game/sports-trivia-title-icon.png">
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import UserDataService from "@/services/userDataService";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "Header",
  data() {
    return {
      displayCoinsContainer: undefined,
    }
  },
  computed: {
    ...mapState(['pointScore', 'user']),
  },
  methods: {
    ...mapMutations(['setPointScore']),
    async getUserDailyPointScore() {
      const getUserResultsResponse = await UserDataService.getUserResults(this.user.msisdn);
      const key = Object.keys(getUserResultsResponse.data.recordset[0])
      const pointScore = Object.values(getUserResultsResponse.data.recordset[0]);
      if (key[0] === 'TotalCorrectAnswersToday') {
        this.displayCoinsContainer = true;
        this.setPointScore(pointScore[0] * 100);
      }
    },
  },
  async beforeMount() {
    await this.getUserDailyPointScore();
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/landing-page' || this.$route.path === '/quiz-completed') {
        this.getUserDailyPointScore();
      }
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.multiply-text {
  font-family: "Komikax", sans-serif;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

.points-text {
  font-family: "Komikax", sans-serif;
  font-size: 10px;
  margin-left: 3px;
}

.body-container {
  position: relative;
  height: 100%;
}

.coins-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
}

@media screen and (max-width: 390px) {
  .trivia-title-img {
    width: 180px;
    margin-bottom: 20px;
  }
}
</style>

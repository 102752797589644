<template>
  <div class="d-flex flex-column justify-content-start align-items-center body-container">
    <div class="d-flex flex-row error-header-text col-9 p-1">OOPS!</div>
    <img class="col-9" src="../assets/game/underline-icon.png">
    <div class="d-flex flex-row col-9 p-1">Something went wrong.</div>
    <div class="d-flex flex-row col-9 p-1">Please try again.</div>
    <button @click="tryAgainButtonClicked()" class="mt-5 col-9 try-again-button">Try Again</button>
    <div @click="goHomeButtonClicked()" class="mt-3 go-home-text">Go Home</div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  methods: {
    tryAgainButtonClicked() {
      console.log('Ask Rubey what he would like this button to do')
    },
    goHomeButtonClicked() {
      this.$router.push('/landing-page')
    }
  },
}
</script>

<style scoped>
.body-container {
  color: #FFFFFF;
}

.error-header-text {
  margin-top: 50px;
  font-weight: 600;
  font-size: 24px;
}

.try-again-button {
  height: 50px;
  border: 1px solid #FFFFFF;
  background-color: #950033;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
}

.go-home-text {
  font-weight: 700;
  font-size: 18px;
}

</style>

<template>
  <div class="d-flex flex-column align-items-center text-center body-container" v-if="!this.isLoading">
    <div class="promo-text col-10">Know the beautiful game</div>
    <img class="mt-1 col-10" src="../assets/game/underline-icon.png">
    <div class="mt-4 how-it-works-info-container">
      <img class="col-10 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
      <div class="col-9 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">Answer the 10 daily questions</div>
    </div>
    <div class="mt-4 how-it-works-info-container">
      <img class="col-10 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
      <div class="col-9 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">You have 60 seconds to answer each question or
        you fail the quiz
      </div>
    </div>
    <div class="mt-4 how-it-works-info-container">
      <img class="col-10 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
      <div class="col-9 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">Win 100 points for every correct answer!</div>
    </div>
    <div class="mt-4 how-it-works-info-container">
      <img class="col-10 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
      <div class="col-9 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">Points won go into a draw to win one of 30 250MB bundles awarded daily
      </div>
    </div>
    <button @click="backButtonClicked()" class="mt-3 col-10 col-sm-8 col-md-9 col-lg-10 col-xl-11 back-button">BACK
    </button>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: "HowItWorks",
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    backButtonClicked() {
      this.$router.push('/landing-page')
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Reey Regular";
  src: local("Reey Regular"),
  url(../assets/fonts/Reey-Regular.otf) format("truetype");
}

.body-container {
  color: #FFFFFF;
}

.promo-text {
  font-family: "Reey Regular", serif;
  margin-top: 60px;
  font-size: 18px;
}

.how-it-works-info-container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.back-button {
  height: 50px;
  border: 1px solid #FFFFFF;
  background-color: #950033;
  font-family: "Komikax", sans-serif;
  border-radius: 12px;
  color: #FFFFFF;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
import LandingPage from "@/views/LandingPage";
import HowItWorks from "@/views/HowItWorks";
import QuestionPage from "@/views/QuestionPage";
import ResultsPage from "@/views/ResultsPage";
import TermsConditions from "@/views/TermsConditions";
import ErrorPage from "@/views/ErrorPage";
import MarketingAlerts from "@/views/MarketingAlerts";
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
    {
        path: '/',
        redirect: 'landing-page',
    },
    {
        path: '/landing-page',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/question-page',
        name: 'QuestionPage',
        component: QuestionPage,
    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        component: HowItWorks,
    },
    {
        path: '/quiz-completed',
        name: 'ResultsPage',
        component: ResultsPage,
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsConditions',
        component: TermsConditions,
    },
    {
        path: '/error-page',
        name: 'ErrorPage',
        component: ErrorPage,
    },
    {
        path: '/alerts-page',
        name: 'MarketingAlerts',
        component: MarketingAlerts,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

export default router

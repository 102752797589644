import axios from "axios";
import {environmentConfig} from "../config/enviromentConfig";

export default class UserDataService {
    static async getUser(userSubscriptionId) {
        return await axios.post(`${environmentConfig.triviaService}/get-user`, {
            subscriptionId: parseInt(userSubscriptionId),
            serviceId: "vc-fanclash-quizinc-01",
            externalRef: 1233564
        })
    }

    static async getUserResults(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-results`, {
            msisdn: msisdn,
        })
    }

}

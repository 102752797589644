import axios from "axios";
import {environmentConfig} from "../config/enviromentConfig";

export default class TriviaDataService {
    static async getTriviaQuestions(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions`, {
            msisdn: msisdn
        })
    }

    static async submitUserAnswers(answers) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers`, {
            answers: answers
        })
    }
}

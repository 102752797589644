import UserDataService from "@/services/userDataService";
import store from "@/store";

export async function userAuthenticate(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const subscriptionId = localStorage.getItem('qi-subscription-id');

    const queryParamSubscriptionId = urlParams.get('qi-subscription-id')?.toString();

    const userSubscriptionId = queryParamSubscriptionId ? atob(queryParamSubscriptionId) : subscriptionId;
    if (userSubscriptionId) await setUserData(userSubscriptionId, next);
    else return window.open(`https://www.quizinc.co.za`, '_self');
}

async function setUserData(userSubscriptionId, next) {
    const user = await UserDataService.getUser(userSubscriptionId);

    if (user.data.result === "true") {
        store.commit('setUser', user.data);
        localStorage.setItem('qi-subscription-id', userSubscriptionId);
        store.commit('setIsLoading', false);
        return next();
    } else return window.open(` http://optin.quizinc.co.za/service/?qi-subscription-id=${userSubscriptionId}`, '_self');
}

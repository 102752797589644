<template>
  <div class="d-flex flex-column align-items-center text-center body-container" v-if="arrayTriviaQuestions && !this.isLoading">
    <div class="mt-5 col-9 question-text">{{ arrayTriviaQuestions[questionIndex].question }}
    </div>
    <img class="mt-1 mb-3 col-9" src="../assets/game/underline-icon.png">
    <div class="mt-3">
      <div class="point-answer"
           @click="answerSubmitted(arrayTriviaQuestions[questionIndex].answers[0], arrayTriviaQuestions[questionIndex].questionId, arrayTriviaQuestions[questionIndex].questionCategoryId)">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
        <div class="alphabet-text">A</div>
        <div class="col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">
          {{ arrayTriviaQuestions[questionIndex].answers[0] }}
        </div>
      </div>
      <div class="mt-3 point-answer"
           @click="answerSubmitted(arrayTriviaQuestions[questionIndex].answers[1], arrayTriviaQuestions[questionIndex].questionId, arrayTriviaQuestions[questionIndex].questionCategoryId)">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
        <div class="alphabet-text">B</div>
        <div class="col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">
          {{ arrayTriviaQuestions[questionIndex].answers[1] }}
        </div>
      </div>
      <div class="mt-3 point-answer"
           @click="answerSubmitted(arrayTriviaQuestions[questionIndex].answers[2], arrayTriviaQuestions[questionIndex].questionId , arrayTriviaQuestions[questionIndex].questionCategoryId)">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
        <div class="alphabet-text">C</div>
        <div class="col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">
          {{ arrayTriviaQuestions[questionIndex].answers[2] }}
        </div>
      </div>
      <div class="mt-3 point-answer"
           @click="answerSubmitted(arrayTriviaQuestions[questionIndex].answers[3], arrayTriviaQuestions[questionIndex].questionId, arrayTriviaQuestions[questionIndex].questionCategoryId)">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../assets/game/button-clear-icon.png">
        <div class="alphabet-text">D</div>
        <div class="col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 centered">
          {{ arrayTriviaQuestions[questionIndex].answers[3] }}
        </div>
      </div>
    </div>
    <div class="mt-3">
      Question {{ questionIndex + 1 }}/10
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <img class="mt-2 col-2" src="../assets/game/clock-icon.png">
      <div class="mt-2 second-countdown-text">{{ timerCount }}<span>s</span></div>
    </div>
  </div>
</template>

<script>
import TriviaDataService from "@/services/triviaDataService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "QuestionPage",
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  data() {
    return {
      timerCount: 60,
      questionIndex: 0,
      arrayTriviaQuestions: undefined,
      currentQuestion: undefined,
      userAnswersArray: [],
      quizComplete: false
    }
  },
  methods: {
    ...mapMutations(['setUserCorrectAnswers', 'setCompletedQuiz', 'setCompletedQuestions', 'setIsLoading']),
    async handleTimerFinish(timer) {
      if (timer === 0 && !this.quizComplete) {
        await this.submitUserAnswers();
        this.$router.push('/quiz-completed');
      }
    },
    async answerSubmitted(answer, questionId, questionCategoryId) {
      this.questionIndex++;
      this.pushToUserAnswersArray(answer, questionId, questionCategoryId);
      await this.handleQuizQuestionsCompleted();
    },
    pushToUserAnswersArray(answer, questionId, questionCategoryId) {
      const answerObject = {
        msisdn: this.user.msisdn,
        questionCategoryId: questionCategoryId,
        answer: answer,
        questionId: questionId
      };
      this.userAnswersArray.push(answerObject);
    },
    async handleQuizQuestionsCompleted() {
      if (this.questionIndex === 10) {
        this.quizComplete = true;
        await this.submitUserAnswers();
        this.$router.push('/quiz-completed');
      }
    },
    async submitUserAnswers() {
      this.checkForNoAnswers();
      let userAnswerSubmissionResponse = await TriviaDataService.submitUserAnswers(this.userAnswersArray);
      let correctAnswers = userAnswerSubmissionResponse.data.recordset[0]["TotalCorrectAnswersToday"]
      this.setUserCorrectAnswers(correctAnswers);
    },
    checkForNoAnswers() {
      if (Object.keys(this.userAnswersArray).length < 10) {
        this.setCompletedQuiz(false)
        this.setCompletedQuestions(this.userAnswersArray.length);
        for (let i = this.userAnswersArray.length; i < 10; i++) {
          const key = Object.keys(this.arrayTriviaQuestions)[i];
          const value = this.arrayTriviaQuestions[key]
          const answerObject = {
            msisdn: this.user.msisdn,
            questionCategoryId: value.questionCategoryId,
            answer: null,
            questionId: value.questionId
          };
          this.userAnswersArray.push(answerObject);
        }
      }
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        this.handleTimerFinish(value);
      },
      immediate: true
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    const userTriviaQuestionsResponse = await TriviaDataService.getTriviaQuestions(this.user.msisdn);
    this.arrayTriviaQuestions = userTriviaQuestionsResponse.data;
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.body-container {
  font-size: 16px;
  color: #FFFFFF;
}

.point-answer {
  position: relative;
  text-align: center;
  color: white;
}

.alphabet-text {
  position: absolute;
  top: 15%;
  left: 17%;
  font-family: "Komikax", sans-serif;
  font-size: 24px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.second-countdown-text {
  font-family: "Komikax", sans-serif;
  font-size: 24px;
  margin-left: 10px;
}

span {
  font-size: 16px;
}
</style>

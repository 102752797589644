import { createStore } from 'vuex'

export default createStore({
  state: {
    user: undefined,
    userCorrectAnswers: undefined,
    completedQuiz: true,
    completedQuestions: undefined,
    pointScore: undefined,
    isLoading: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserCorrectAnswers(state, userCorrectAnswers) {
      state.userCorrectAnswers = userCorrectAnswers
    },
    setCompletedQuiz(state, completedQuiz) {
      state.completedQuiz = completedQuiz
    },
    setCompletedQuestions(state, completedQuestions) {
      state.completedQuestions = completedQuestions
    },
    setPointScore(state, pointScore) {
      state.pointScore = pointScore
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div class="d-flex flex-column justify-content-start align-items-center body-container">
    <div class="d-flex flex-row alerts-header-text col-9 p-1">
      <div v-if="marketingAlerts">ALERTS ON!</div>
      <div v-if="!marketingAlerts">ALERTS OFF!</div>
    </div>
    <img class="col-9" src="../assets/game/underline-icon.png">
    <div class="d-flex flex-row col-9 p-1">
      <div v-if="marketingAlerts">Fantastic! You've activated daily alerts to remind you to play!</div>
      <div v-if="!marketingAlerts">You have opted out of daily alerts! You can still play, score points and stand to WIN
        daily prizes!
      </div>
    </div>
    <img v-if="marketingAlerts" class="mt-3 col-6" src="../assets/game/notifications-on-icon.png">
    <img v-if="!marketingAlerts" class="mt-3 col-6" src="../assets/game/notifications-off-icon.png">
    <button @click="goHomeButtonClicked()" class="mt-4 col-9 go-home-button">Go Home</button>
  </div>
</template>

<script>
export default {
  name: "MarketingAlerts",
  data() {
    return {
      marketingAlerts: true,
    }
  },
  methods: {
    goHomeButtonClicked() {
      this.$router.push('/landing-page')
    }
  },
}
</script>

<style scoped>
.body-container {
  color: #FFFFFF;
}

.alerts-header-text {
  margin-top: 50px;
  font-weight: 600;
  font-size: 24px;
}

.go-home-button {
  height: 50px;
  border: 1px solid #FFFFFF;
  background-color: #950033;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
}


</style>

<template>
  <div class="container-fluid mt-5">
    <div class="d-flex flex-column justify-content-center align-content-center">
      <h3><u>TERMS AND CONDITIONS:</u></h3>
      <h3><u>SPORTS TRIVIA 225 GB GIVEAWAY COMPETITION</u></h3>
    </div>
    <div class="d-flex mt-2">
      <div>1</div>
      <div><u>Introduction</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>1.1</div>
      <div>Vodacom (Pty) Ltd (“<b>Vodacom</b>”) and Fanclash (Pty) Ltd (“<b>Partner</b>”) running
        Competition where customers stand a chance of winning their share of 225 GB of data
        by subscribing to Quizinc VIP service (“<b>the Service</b>”) and playing the Sports Trivia
        competition (<b>the</b> “<b>Competition</b>”).
      </div>
    </div>
    <div class="d-flex mt-2">
      <h3><u>IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS</u></h3>
    </div>
    <div class="d-flex mt-2">
      <h3><b><u>PLEASE READ THESE TERMS AND CONDITIONS AND THE PRIVACY POLICY
        CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY ENTERING
        THE COMEPTITION, YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND
        CONDITIONS.IF YOU DO NOT AGREE, PLEASE DO NOT ENTER THE
        COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE RESERVE
        THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND
        CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND
        CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL APPLY
        TO YOU AND YOUR ENTRY INTO THE COMPETITION AND YOU SHALL BE
        DEEMED TO HAVE ACCEPTED SUCH CHANGES.</u></b></h3>
    </div>
    <div class="d-flex mt-2">
      <h3 style="color: red"><b><u>ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE
        FOLLOWING TERMS AND CONDITIONS:</u></b></h3>
    </div>
    <div class="d-flex mt-2">
      <div>2</div>
      <div><u>Duration</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>2.1</div>
      <div>This Competition shall commence on 00h01 on 20 November 2022 to 23h59 on 20
        December 2022 (<b>the</b> “<b>End Date</b>”) (“<b>the Competition period</b>”)..
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>2.2</div>
      <div>The duration of the Competition may be extended or curtailed at the discretion of
        Vodacom.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>3</div>
      <div><u>Prizes</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>3.1</div>
      <div>Participants to the Competition stand a chance to win their share of 225GB of data
        during the Competition Period made up as follows:
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>3.1.1</div>
      <div>a total of 900 (nine hundred) Vodacom data bundles of 250 MB each will be given
        away (“the Prize”).
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>3.2</div>
      <div>Winners may win multiple times during the Competition period.</div>
    </div>
    <div class="d-flex mt-2">
      <div>4</div>
      <div><u>Eligibility:</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>4.1</div>
      <div>Only entries received during the Competition period will qualify to enter the
        Competition.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2</div>
      <div>The following requirements must be complied with during the Competition period in
        order to qualify for entry into the Competition:
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.1</div>
      <div>be a natural person;</div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.2</div>
      <div>be at least 18 years and older;</div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.3</div>
      <div>be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;</div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.4</div>
      <div>reside in South Africa and be in possession of a valid South African identify
        document or South African permanent residence permit or a South African temporary
        residence permit valid for a period exceeding 3(three) months which permits shall be
        valid as at the End Date; and
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.5</div>
      <div>use a SIM card that has been RICA registered;</div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.6</div>
      <div>subscribe to the Service for R3,00 (three Rand) per day;</div>
    </div>
    <div class="d-flex mt-2">
      <div>4.2.7</div>
      <div>click on the “Sports Trivia” competition banner on the Service; and
        play the “Sports Trivia” competition on the Service and correctly answer at least 6
        (six) of the 12 (twelve) questions within the allocated time limit per question for each
        day of the Competition Period. Each question answered correctly counts as 100
        entries into that day’s lucky draw.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.3</div>
      <div>Employees, directors and agents of Vodacom and the Partner (where applicable) all
        participating stores, their immediate families, life partners, business partners and
        associates and any person directly involved with the sponsoring, devising, production,
        management or marketing of this Competition are not eligible to enter this
        Competition.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.4</div>
      <div>In the event that the registered user of the SIM Card or Vodacom account (i.e, the
        participant in the Competition) is not the person that is responsible for payment of the
        Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
        to the person that is responsible for payment of the Vodacom account.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>4.5</div>
      <div>Entry to the Competition is free. Standard data costs apply as per the user’s Service
        Provider and existing price plan for data usage. Vodacom shall not be liable for any
        costs incurred by the winners for claiming any Prizes, where applicable.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>5</div>
      <div><u>Prize draw:</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>5.1</div>
      <div>For each day of the Competition Period, 30 (thirty) winners will be randomly selected
        from all eligible entries who meet all the requirements set out in clause 4 above.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>5.2</div>
      <div>Winners can win multiple times.</div>
    </div>
    <div class="d-flex mt-2">
      <div>6</div>
      <div><u>Winner Validation</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>6.1</div>
      <div>All the information provided or related to this Competition shall be managed, captured
        and approved by Vodacom and/or the Partner (where applicable).
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>6.2</div>
      <div>Vodacom and/or the Partner (where applicable) will use reasonable efforts to contact
        the winners telephonically or via SMS on the contact details provided by the
        participants by signing up for the Service.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>6.3</div>
      <div>The claim for any Prize will be subject to security and validation, and Vodacom
        reserves the right to withhold any Prize until it is satisfied that the claim by a particular
        winner is valid. The selected winners must be able to identify themselves, in a manner
        determined by Vodacom, as the qualifying participants to the Competition and are
        required to comply with the validation procedure, determined by Vodacom, to claim
        any Prizes, failing which such winner will forfeit the Prize and the Prize will be awarded
        to the next selected qualifying participant.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>6.4</div>
      <div>Vodacom and/or the Partner (where applicable) shall attempt to contact the winners
        for a period of 05 (five) working days after their names have been drawn and verified
        as winners. The winners will be contacted during normal working hours, between
        09:00 to 16:00. Winners will be advised of arrangements in respect of Prizes at the
        time of contacting the selected winners. Should a participant not be available on the
        contact details provided during the timeframe stipulated above or rejects, forfeits or
        declines acceptance of the Prize, that person&#39;s right to the Prize will be deemed to
        have been waived and the Prize will be forfeited. Vodacom reserves the right to then
        award the Prize to the next randomly drawn participant.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>6.5</div>
      <div>In the event that Vodacom requires certain documentation to be submitted by a
        particular winner and same has not been received at the agreed cut-off date
        communicated to the winner, or no effort is made on the part of any of the winners to
        make special arrangements to meet the deadline set by Vodacom, such Prize will be
        forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
        in line.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>6.6</div>
      <div>Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
        writing to their image and/or likeness being used and published by Vodacom in
        connection with the Competition for a period of 12 (twelve) months after they have
        been announced as winners. The winners may decline the use of their image and/or
        likeness by Vodacom.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>7</div>
      <div><u>Prize Delivery</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>7.1</div>
      <div>All Prizes will be sent by SMS to the number used when registering the Service.</div>
    </div>
    <div class="d-flex mt-2">
      <div>7.2</div>
      <div>If a winner cannot accept a Prize for any reason whatsoever, the Prize will be awarded
        to the next selected participant.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>7.3</div>
      <div>All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
        thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
        regard to the Prizes shall terminate.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>8</div>
      <div><u>Personal information</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>8.1</div>
      <div>User data, including personal information collected via the Competition will not be
        used for any other purpose than for execution of the Competition and will be
        processed in line with the Vodacom’s Privacy Statement which is incorporated into
        these terms and conditions. The Privacy Statement sets out how amongst other things
        Vodacom collects, use and shares subscribers’ personal information and how it
        protects subscriber’s privacy when processing their information.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>8.2</div>
      <div>By continuing, a subscriber confirms that the Vodacom Privacy Statement has been
        read and understood. The latest Privacy Statement can be found on the Vodacom
        website at https://www.vodacom.co.za/vodacom/privacy-
        policy/terms?icmp=Home/Footer/PrivacyPolicy.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9</div>
      <div><u>General</u></div>
    </div>
    <div class="d-flex mt-2">
      <div>9.1</div>
      <div>Nothing in these Terms and Conditions is intended to, or must be understood to,
        unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
        for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
        2008 (&quot;CPA&quot;).
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.2</div>
      <div>The Prize is not transferable and is not exchangeable for another Prize or cash and
        cannot be sold.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.3</div>
      <div>The decision of Vodacom in respect of disputes arising out of this Competition shall be
        dealt with by Vodacom in terms of these Terms and Conditions. The decision of
        Vodacom in this regard shall be final and binding on the Parties and no
        correspondence will be entered into.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.4</div>
      <div>In the event of a dispute with regard to any aspect of the Competition and/or the
        Terms and Conditions, Vodacom’s decision will be final, and binding and no
        correspondence will be entered into.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.5</div>
      <div>Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
        Terms and Conditions have not been adhered to or if it detects any irregularities or
        fraudulent practices. Vodacom is entitled in its entire discretion to reject any
        participant for any reason and will not be obliged to notify participants that they have
        not been successful.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.6</div>
      <div>Vodacom and/or the Partner and/or any other person or party associated with the
        Competition, their associated companies, agents, contractors and sponsors and any of
        its personnel involved in the Competition, shall not be liable whatsoever for any loss or
        damage incurred or suffered (including but not limited to direct or indirect or
        consequential loss), death or personal injury suffered or sustained arising from either
        the participant entering the Competition or from it claiming a Prize.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.7</div>
      <div>Vodacom is not liable for any technical failures affecting participation and / or Prize
        redemption process of the Competition. In addition, neither Vodacom nor its agents
        shall be responsible for any loss or misdirected entries, including entries that were not
        received due to any failure of hardware, software, or other computer or technical
        systems affecting participating in and/or the Prize redemption process of the
        Competition.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.8</div>
      <div>Vodacom, its directors, employees, agents, and distributors, are not responsible for
        any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
        of any warranties, guarantees or undertakings given by any person other than
        Vodacom itself.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.9</div>
      <div>If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
        or cancel any aspect of the Competition, it will have the right to terminate the
        Competition being offered, with immediate effect and upon written notice being
        published on the Vodacom website. In such event all participants hereby waive any
        rights which they may have against Vodacom and its associated companies, agents,
        contractors and/or sponsors.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.10</div>
      <div>All information relating to the Competition which is published on any marketing
        material will form part of these Terms and Conditions. In the event of any conflict
        between such marketing material and these Terms and Conditions, these Terms and
        Conditions shall prevail.
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>9.11</div>
      <div>The Competition is also subject to, and must be read in conjunction with, Vodacom’s
        existing terms and conditions applicable to its website and mobile site respectively.,
        which terms and conditions are available on the Vodacom website.
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "TermsConditions"
}
</script>

<style scoped>

</style>

<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" :class="getAppClass()" v-if="user">
      <div v-if="this.$route.path !== '/terms-and-conditions'" class="header-container">
        <Header/>
      </div>
      <div v-if="this.$route.path !== '/terms-and-conditions'" class="content-container">
        <LoadingOverlay v-if="this.isLoading"/>
        <router-view/>
      </div>
      <div v-if="this.$route.path === '/terms-and-conditions'" class="content-container-no-bg">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import LoadingOverlay from "@/components/LoadingOverlay";
import {mapState} from "vuex";

export default {
  components: {Header, LoadingOverlay},
  computed: {
    ...mapState(['user', 'isLoading'])
  },
  methods: {
    getAppClass() {
      if (this.$route.path === "/terms-and-conditions") return "app-container-scroll"
      else return "app-container-no-scroll"
    }
  }
}

</script>

<style>

.app-container-no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.app-container-scroll {
  height: 100vh;
}

.header-container {
  height: 30%;
  background-image: url("./assets/game/header-background.png");
  background-size: cover;
  z-index: 0;
}

.content-container {
  z-index: 1;
  height: 80%;
  background-image: url("./assets/game/content-background.png");
  background-size: cover;
  margin-top: -55px;
}

.content-container-no-bg {
  margin-top: -55px;
}

@media screen and (max-width: 390px) {
  .header-container {
    height: 20%;
    background-image: url("./assets/game/header-background.png");
    background-size: cover;
    z-index: 0;
  }

  .content-container {
    z-index: 1;
    height: 85%;
    background-image: url("./assets/game/content-background.png");
    background-size: cover;
    margin-top: -55px;
  }
}
</style>
